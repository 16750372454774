import { Component, OnInit } from '@angular/core';
import { SharedserviceService } from 'src/app/services';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  ownerData: any;
  myData:any;
  constructor(public sharedService: SharedserviceService) { 
    this.ownerData= JSON.parse(sessionStorage.getItem("ownerData"));

  }
  
  scrollToTop(){
    // window.scroll(0,0);
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  ngOnInit() {
    this.contactDetail();
    console.log("check ownerData",this.ownerData);
    
  }

  contactDetail() {
    const self = this;
    self.sharedService.get('v1/users/get_website_owner_data').subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.myData = res.data;
        console.log("get website owner Data::::::::::::::",this.myData);
        sessionStorage.setItem("ownerData", JSON.stringify(this.myData) );
      } else {
        
      }
    });
  }


}
