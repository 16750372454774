import { Component, HostListener, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  getData:any;
  isSticky: boolean = false;
  submitted: boolean = false;
  sidenav: any;
  user_id: any;
  role_id: any;
  user: any;
  cData: any;
  ccData: any;
  compData: any;
  resltData: any;
  available_balance: any;
  data: any;
  isMobile: boolean = false;
  width: number = window.innerWidth;
  mobileWidth: number = 500;

  TotalCount: any;
  keys: any;
  bodydata: any = {};
  searchtext :any;
  public innerWidth: any;
  formEmail:FormGroup;
  myContactData: any;
  ownerData: any;
  constructor(
    private router: Router, 
    public ngxloader: NgxUiLoaderService,
    private fb: FormBuilder, 
    public cds: CommonService, 
    public sharedService: SharedserviceService,
    private toastr: ToastrManager,) {
      this.ownerData = JSON.parse(localStorage.getItem('ownerData'));
      console.log("check ownerData ===========>>>>",this.ownerData);
      
  }

  golink(){
    if(this.searchtext != ""){
      if(this.searchtext == undefined){
        this.searchtext="";
      }
      sessionStorage.setItem("sidebar_searchtext", JSON.stringify(this.searchtext))
      this.router.navigateByUrl('search-result');
    }
  }

  ngOnInit(){

    this.contactDetail();
    this.innerWidth = window.innerWidth;
    if(this.innerWidth < 769){
      this.isExpanded = false;
    } 
    else{
      this.isExpanded = true
    }

    

    this.user = JSON.parse(localStorage.getItem("userAgency"));
    if (this.user != null) {
      this.user_id = this.user.id;
      this.role_id = this.user.role_id;
      if(this.cds.b_update == false){
        this.cds.user_balance=this.user.available_balance;
      }

    }

    // this.isMobile = this.width < this.mobileWidth;
    window.addEventListener('scroll', this.scroll, true);

    this.bodydata = {
      page_limit: "10",
      page_no: this.bodydata['page_no'],
      search_value: "",
      order_key: "desc",
      order_value: ""
    }
    this.bodydata['page_no'] = 1;
    // this.accountDtl(this.bodydata);
    this.formEmail = this.fb.group({
      email: [this.user.email],
      name: [this.user.name],
      subject: ['', Validators.required],
      message: ['',Validators.required],
      mobile_no: [this.user.mobile],
      company_name: [this.user.company_name == null ? 'New Company' :  this.user.company_name],
      send_to_email:[''],
      // send_to_email:['sunil.fareboutique@gmail.com'],
    });
    
  }
  get enq() { return this.formEmail.controls; }

  enquiryForm(){
    this.submitted = true;
    if(this.formEmail.invalid){
      return
    }else{
    // var commentdata={
    //   "sector": this.formEmail.value.sector,
    //   "date": this.formEmail.value.date,
    //   "no_pax": this.formEmail.value.no_pax,
    //   "expected_fare": this.formEmail.value.expected_fare
    // }
    var requestData ={
      "name":this.formEmail.value.name,
      "email":this.formEmail.value.email,
      "subject": this.formEmail.value.subject,
      // "message": this.formEmail.value.message+" "+JSON.stringify(commentdata),
      "message": this.formEmail.value.message,
      "company_name": this.formEmail.value.company_name,
      "mobile_no": this.formEmail.value.mobile_no.toString(),
      "send_to_email":this.ownerData.email
      // "send_to_email":this.getData.email
      // "send_to_email":"sunil.fareboutique@gmail.com"
    }
  
    this.sharedService.post('v1/pages/contact_us',requestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        $('#enquiryFormNew').modal('hide');
        this.formEmail.reset();
        this.myContactData = res.data;
        console.log("::::::::::::::",this.myContactData);
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
    }
  }

  enquiryModal(){
    $('#enquiryFormNew').modal('show');
  }

  gotoinventory(val) {
    // [routerLink]="['/master/inventory-one-way']"
    var val1 = 'dashboard'
    this.ngxloader.start();

    this.router.navigateByUrl(val);
  }

  // onWindowResize(event) {
  //   this.width = event.target.innerWidth;
  //   this.isMobile = this.width < this.mobileWidth;
  //   if (this.isMobile = this.width < this.mobileWidth) {
  //     this.isMobile = true;
  //     this.isExpanded = false;
  //   }
  //   else {
  //     this.isMobile = false;
  //     this.isExpanded = true;
  //   }
  // }

  // @ViewChild('sidenav') sidenav: MatSidenav;
  logout() {
    // localStorage.clear();
    // sessionStorage.clear();
    localStorage.removeItem("tokenAgency");
    localStorage.removeItem("userAgency");
    this.router.navigateByUrl('/home-sector')
  }
  isExpanded = true;
  showSubmenu: boolean = false;
  showSubmenu2: boolean = false;
  showSubmenu3: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }

  }


  // @HostListener('window:scroll', ['$event'])
  // checkScroll() {
  //   this.isSticky = window.pageYOffset >= 20;
  // }
  // contactShow() {
  //   $('#contactUs').modal('show');
  // }
  closeContact() {
    $('#contactUs').modal('hide');
    $('#enquiryFormNew').modal('hide');

  }


  // accountDtl(bodydata) {
  //   const self = this;
  //   self.sharedService.post('v1/vendor_account/company_account_details', bodydata).subscribe((res: any) => {
  //     if (res.replyCode == "success") {
  //       this.ccData = res.data;
  //       this.resltData = res;

  //       if ((res.data).length > 0) {
  //         this.data = res.data;
  //         this.compDtl = res.company_details;

  //         //console.log("CompanyDetails:::::::::::::",this.compDtl);
  //         this.keys = Object.keys(this.data[0]);
  //         this.TotalCount = res.totalRecords;
  //       } else {
  //         // this.msg ='No Record Found..'
  //       }
  //     } else {
  //       //  self.toastr.errorToastr("Result Not Found","Error");
  //     }
  //   }, err => {
  //     //  self.toastr.errorToastr(err.error.replyMsg,"Error");
  //   });
  // }

  // getBalance(bodydata) {
  //   const self = this;
  //   self.sharedService.post('v1/vendor_account/get_balance', bodydata).subscribe((res: any) => {
  //     if (res.replyCode == "success") {
  //       this.getData = res.data;
  //       var updatedData = JSON.parse(localStorage.getItem('userAgency'));
  //       console.log("first Data",updatedData);
        
  //        updatedData.available_balance=this.getData.available_balance;
  //       console.log("second Data",updatedData);

  //        localStorage.removeItem('userAgency');
  //        setTimeout(() => {
  //         localStorage.setItem('userAgency',updatedData);
  //        }, 100);
  //     } else {
  //       //  self.toastr.errorToastr("Result Not Found","Error");
  //     }
  //   });
  // }
  mycontact(){
    this.contactDetail();
    $('#contactUs').modal('show');
  }
  contactDetail() {
    const self = this;
    self.sharedService.get('v1/users/get_website_owner_data').subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.getData = res.data;
        // $('#contactUs').modal('show');
      } else {
        //  self.toastr.errorToastr("Result Not Found","Error");
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    this.isSticky = window.pageYOffset >= 20;
  };


}

