import { Component } from '@angular/core';
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fareboutique';
  browser:any;
  

  ngOnInit() {
    
//console.log(this.myBrowser());
    this.browser=this.myBrowser();
    // 
//console.log(this.getBrowserVersion());
  }
  myBrowser() { 
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'Opera';
    }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
        return 'Chrome';
    }else if(navigator.userAgent.indexOf("Safari") != -1){
        return 'Safari';
    }else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
         return 'Firefox';
    }else if(navigator.userAgent.indexOf("MSIE") != -1 ) {
    // }else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )){
      return 'IE'; 
    } else {
       return 'unknown';
    }
}


  // constructor( private dateAdapter: DateAdapter<Date>,){
  //   this.dateAdapter.setLocale('en-GB');
  // }

  // public getBrowserName() {
  //   const agent = window.navigator.userAgent.toLowerCase()
  //   switch (true) {
  //     case agent.indexOf('edge') > -1:
  //       return 'edge';
  //       case agent.indexOf('opr') > -1 && !!(<any>window).opr:
  //       return 'opera';
  //       case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
  //       return 'chrome';
  //       case agent.indexOf('trident') > -1:
  //       return 'ie';
  //       case agent.indexOf('firefox') > -1:
  //       return 'firefox';
  //       case agent.indexOf('safari') > -1:
  //         return 'safari';
  //         default:
  //           return 'other';
  //         }
  //         
//console.log("browser Name",this.getBrowserName);
          
  //   }



  
}
