import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { ReplaceUnderscorePipe,TokenGuard } from '../services';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialModule } from '../route-file/material.module';
import { MyDatePickerModule } from 'mydatepicker';
import { DropDownButtonAllModule } from '@syncfusion/ej2-angular-splitbuttons';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { MaskedTextBoxModule, UploaderAllModule } from '@syncfusion/ej2-angular-inputs'
import { ToolbarAllModule, ContextMenuAllModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonAllModule, CheckBoxAllModule, SwitchAllModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerAllModule, TimePickerAllModule, DateTimePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { NumericTextBoxAllModule, TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { ScheduleAllModule, RecurrenceEditorAllModule } from '@syncfusion/ej2-angular-schedule';
import { OrderModule } from 'ngx-order-pipe';
// import { AppDashboardComponent } from './app-dashboard/app-dashboard.component';
// import { MydashboardComponent } from './mydashboard/mydashboard.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  // { path: 'homedashboard', component: AppDashboardComponent },
  // { path: 'mydashboard', component: MydashboardComponent },
];


const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgxFullCalendarModule,
  NgxPaginationModule,MyDatePickerModule,
  MaterialModule
];
const CalendarModule =[
  ScheduleAllModule, RecurrenceEditorAllModule,   NumericTextBoxAllModule, TextBoxAllModule, DatePickerAllModule, TimePickerAllModule, DateTimePickerAllModule, CheckBoxAllModule,   ToolbarAllModule, DropDownListAllModule, ContextMenuAllModule, MaskedTextBoxModule, UploaderAllModule, MultiSelectAllModule,   TreeViewModule, ButtonAllModule, DropDownButtonAllModule, SwitchAllModule,
]
// const COMMON_COMPONENTS = [
//   ReplaceUnderscorePipe
// ];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
@NgModule({
  imports: [...BASE_MODULES,...CalendarModule,RouterModule.forChild(routes),OrderModule],
  exports: [RouterModule],
  //declarations: [...COMMON_COMPONENTS]
})
export class HomedashboardRoutingModule { }
